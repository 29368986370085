<gea-hrt-recommendation-item
  *ngFor="let item of list | filterByUsageCount: isEditable; let i = index; trackBy: trackByRecommendationId"
  [recommendation]="item"
  [index]="i"
  [isEditable]="isEditable"
  (openImagesDialog)="openImagesDialog($event)"
  (openDeleteRecommendationDialog)="openDeleteRecommendationDialog($event)"
  (actionClicked)="actionClicked.emit($event)"
  (openEditRecommendationDialog)="openEditRecommendationDialog($event)"
  (setRecommendationUseful)="setRecommendationUseful($event)" />
