<div class="recommendation-item">
  <div class="container">
    <geaui-icon-v2 *ngIf="isDraggable" file="scroll-vertical" class="images-button sort-icon" />
    <div class="text">
      <span *ngIf="index !== undefined">{{ index + 1 }}.</span>
      <span> {{ recommendation.actionName }}</span>
    </div>
    <ng-container *ngIf="recommendation.usageCount">
      <span class="usage-count">({{ recommendation.usageCount }})</span>
    </ng-container>

    <div class="actions">
      <geaui-icon-v2
        *ngIf="recommendation.images.length"
        file="image"
        class="images-button"
        (click)="openImagesDialog.emit(recommendation.images)" />

      <ng-container *ngIf="!isDraggable" [ngSwitch]="isEditable">
        <gea-hrt-recommendation-editable-actions
          *ngSwitchCase="true"
          [recommendation]="recommendation"
          (setRecommendationUseful)="setRecommendationUseful.emit(recommendation)"
          (openEditRecommendationDialog)="openEditRecommendationDialog.emit(recommendation)"
          (openDeleteRecommendationDialog)="openDeleteRecommendationDialog.emit(recommendation)" />
        <gea-hrt-recommendation-readonly-actions
          *ngSwitchCase="false"
          [recommendation]="recommendation"
          (actionClicked)="actionClicked.emit($event)" />
      </ng-container>
    </div>
  </div>
</div>
