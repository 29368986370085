import { Injectable } from '@angular/core';

import { ApiErrorResponse } from '@gea/digital-ui-lib';
import { EMPTY, Observable, throwError } from 'rxjs';

import { HrtApiService } from './hrt-api.service';
import { RecommendationDto, RecommendationOrderDto, RecommendationStatus, TroubleshootingDto } from '@shared/models';

const statusActionMapping = {
  [RecommendationStatus.PENDING]: 'setPending',
  [RecommendationStatus.DONE]: 'setDone',
  [RecommendationStatus.SKIPPED]: 'setSkipped',
};

@Injectable({
  providedIn: 'root',
})
export class RecommendationsApiService {
  recommendationsListUrl = 'machines/{machine}/faults/{fault}/recommendations';

  constructor(readonly api: HrtApiService) {}

  fetchRecommendations(machine: number, faultCode: number): Observable<TroubleshootingDto> {
    if (!machine || !faultCode) {
      return throwError(
        () =>
          ({
            message: 'TROUBLESHOOTING.CLIENT_ERROR.NO_FAULT_CHOSEN',
          }) as ApiErrorResponse
      );
    }
    const url = this.recommendationsListUrl.replace('{machine}', machine.toString()).replace('{fault}', faultCode.toString());
    return this.api.getForOrganization<TroubleshootingDto>(url);
  }

  fetchRecommendationsByFaultCode(faultCode: number): Observable<RecommendationDto[]> {
    return this.api.get(`faults/${faultCode}/recommendations`);
  }

  updateRecommendationsOrder(recommendations: RecommendationDto[]) {
    const recommendationOrders: RecommendationOrderDto[] = [];
    recommendations.forEach((value, i) =>
      recommendationOrders.push({
        id: value.id,
        order: i + 1,
        images: value.images?.map((image) => image) || [],
      })
    );
    return this.api.put('settings/catalog/recommendations/order', recommendationOrders);
  }

  setRecommendationStatus(machine: number, faultCode: number, recommendationId: number, status: RecommendationStatus) {
    if (!recommendationId || status === undefined) {
      return EMPTY;
    }
    const baseUrl = this.recommendationsListUrl.replace('{machine}', machine.toString()).replace('{fault}', faultCode.toString());
    const url = `${baseUrl}/${recommendationId}/${statusActionMapping[status]}`;
    return this.api.putForOrganization(url);
  }

  createRecommendation(faultCode: number, actionName: string, images: string[] = []): Observable<RecommendationDto> {
    return this.api.post<RecommendationDto>(`settings/catalog/faults/${faultCode}/recommendations`, { actionName, images });
  }

  updateRecommendation(recommendation: RecommendationDto) {
    recommendation.type = 1;
    return this.api.put(`recommendations/${recommendation.id}`, recommendation);
  }

  deleteRecommendation(recommendationId: number) {
    return this.api.delete(`recommendations/${recommendationId}`);
  }
}
