import { Pipe, PipeTransform } from '@angular/core';
import { RecommendationDto } from '@app/shared/models';

@Pipe({
  name: 'filterByUsageCount',
})
export class FilterByUsageCountPipe implements PipeTransform {
  transform(value: RecommendationDto[], ignoreFiltering: boolean = false): RecommendationDto[] {
    if (ignoreFiltering) {
      return value;
    }

    return value.filter(({ usageCount }) => usageCount && usageCount > 1);
  }
}
